.partner-banner {
  width: 100%;
  height: 500px;
  background: url("../../assets/images/partner-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.partner-banner .partner-detials {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.partner-banner .partners {
  height: 120px;
  width: 120px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  margin-top: 10px;
  padding: 20px;
}
.partners img {
  height: 80px;
  width: 80px;
}
.partner-detials p {
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  max-width: 130px;
}
.partner-data {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner-detials button {
  width: 440px;
  height: 40px;
  background-color: white;
  color: #ff66a4;
  font-weight: bold;
  border: none;
  border-radius: 23px;
  margin-bottom: 20px;
}
.partner-res-banner {
  display: none;
}
@media (max-width: 800px) {
  .partner-banner {
    display: none;
  }
  .partner-res-banner {
    height: auto;
    background: linear-gradient(to right, #ff66a4, #ff99c4, #ff66a4);
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
  .partenr-res-logo {
    font-weight: 600;
    font-size: 8px;
    color: white;
  }
  .partenr-res-logo img {
    margin-top: 5px;
    background-color: white;
    padding: 5px;
    border-radius: 9px;
    height: 60px;
    width: 60px;
    box-shadow: 0px 4px 4px 0px #0000003e;
  }
  .partenr-res-logo button {
    width: 160px;
    height: 20px;
    background-color: white;
    color: #ff66a4;
    font-size: 10px;
    font-weight: bold;
    border: none;
    border-radius: 23px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .partenr-res-head {
    color: white;
  }
}
