.forhumans {
  width: 100%;
  height: 400px;
  background: url("../../assets/images/humans.png");
  background-size: cover;
  display: flex;
  align-items: center;
  margin: 0px !important;
}
.humans-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.humans-header h1 {
  max-width: 300px;
  font-size: 70px;
  font-weight: 700;
  color: white;
}
@media (max-width: 800px) {
  .forhumans {
    height: 100px ;
    margin-bottom:5px !important;
  }
  .humans-header {
    width: 90%;
  }
  .humans-header h1 {
    text-align: right;
    width: 100%;
    font-size: 20px;
  }
  .humans-header i {
    width: 80%;
    text-align: right;
    margin-left: 20px;
    font-size: 12px;
  }
}
