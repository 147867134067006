.animal-welfare {
  width: 100%;
  height: 400px;
  background: url("../../assets/images/animal-welfare.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  margin: 0px !important;
}
.welfare-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.welfare-header h1 {
  max-width: 600px;
  font-size: 70px;
  font-weight: 700;
  color: white;
}
@media (max-width: 800px) {
  .animal-welfare {
    height: 100px;
  }
  .welfare-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  .welfare-header h1 {
    max-width: 800px;
    width: 100%;
    font-size: 18px;
  }
  .welfare-header i{
    font-size: 12px;
  }
}
