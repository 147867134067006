.navbar {
  width: 100%;
  box-shadow: 1px 1px 10px 1px #00000057;
  z-index: 0;
}

.navbar ul {
  width: 100%;
  padding-left: 100px;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.navbar ul li img {
  height: 90px;
  width: 159px;
}

.navbar ul li button {
  background-color: #ff66a3;
  width: 200px;
  height: 41px;
  border-radius: 31px;
  border: none;
  font-weight: 600;
  color: white;
}

.menu {
  display: none;
}

.navbar li a {
  text-decoration: none;
  color: inherit;
}
.navbar .logo a {
  text-decoration: none;
  color: inherit;
}
.navbar ul li a.active-link,
.navbar ul li.active-link a {
  color: #ff66a3; /* Change this to your desired active link color */
}

@media (max-width: 800px) {
  .navbar {
    width: 100%;
    margin-bottom: 0px;
  }

  .navbar ul {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #333;
    position: absolute;
    gap: 10px;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 100%;
    transition: right 0.3s ease-in-out;
    padding-top: 30px;
    font-size: 20px;
    z-index: 1000;
    color: white;
  }
  .navbar ul .menu-close {
    display: block;
  }

  .navbar ul.active {
    display: flex;
    left: 0;
  }

  .navbar ul.active button {
    margin-top: 10px;
    width: 220px;
    border-radius: 5px;
  }
  .navbar ul.active img {
    display: none;
  }
  .menu {
    padding: 6px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .menu button {
    background: none;
    border: none;
  }
  .menu .menubar {
    font-size: 40px;
  }
  .menuicon {
    height: 60px;
    width: 120px;
  }
}
