.pet-supplies {
  width: 100%;
  height: 400px;
  background: url("../../assets/images/pet-supplies.png");
  background-size: cover;
  display: flex;
  align-items: center;
  margin: 0px !important;
}
.supplies-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.supplies-header h1 {
  max-width: 600px;
  font-size: 70px;
  font-weight: 700;
  color: white;
}
@media (max-width: 800px) {
  .pet-supplies {
    height: 100px;
  }
  .supplies-header {
    width: 90%;
  }
  .supplies-header h1 {
    margin-left: 20px;
    text-align: right;
    width: 100%;
    font-size: 20px;
  }
  .supplies-header i {
    width: 80%;
    text-align: right;
    font-size: 12px;
  }
}
