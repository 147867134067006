.featured-videos {
  margin-top: 50px;
}
.featured-title {
  font-size: 22px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 20px;
}

.main-video {
  margin-bottom: 20px;
}

.featured-sub-videos iframe {
  margin-bottom: 15px;
}

.article-card {
  margin-bottom: 20px;
}

.article-images {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}
@media (max-width: 800px) {
}
