.support-section {
  padding: 80px 0px 80px 0px;
  font-family: "Poppins", sans-serif;
}
.support-section .support-body {
  text-align: left;
  gap: 20px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-top: 60px;
}
.support-body .question {
  font-weight: 600;
  font-size: 22px;
}

@media (max-width: 800px) {
  .support-body .question {
    font-weight: 600;
    font-size: 12px;
  }
  .support-body .answer {
    font-size: 10px;
  }
}
