.footer {
  background-color: #dcdcdc;
  padding: 70px 0px 30px 0px;
}
.footer .logo-section img {
  height: 96px;
  width: 165px;
}
.footer ul {
  padding: 5px;
  list-style: none;
}
.footer ul li {
  font-weight: 600;
  text-align: left;
}
.footer-logos {
  padding: 10px;
  gap: 40px;
  display: flex;
}
.footer-logos .app-logos {
  display: flex;
  padding: 10px;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  width: 120px;
}
.footer-logos .app-logos img {
  height: 91px;
}
.footer-logos .play-store {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-logos .play-store img {
  height: 50px;
  width: 130px;
}
@media (max-width: 800px) {
  .footer-row {
    display: flex;
    flex-direction: column;
  }

  .footer .logo-section img {
    height: 60px;
    width: 90px;
  }
  .footer-logos {
    margin-top: 20px;
  }
  .footer-logos .app-logos {
    height: 97px;
  }
  .footer-logos .app-logos img {
    height: 80px;
  }
  .footer-logos .play-store img {
    height: 40px;
    width: 100px;
  }
  .footer-logos {
    padding: 5px;
    gap: 40px;
    display: flex;
  }
  .footer .app-def {
    max-width: 280px;
  }
  .footer .links-section {
    margin-top: 10px;
  }
}
