.home-banner {
  display: flex;
  width: 100%;
  height: 500px;
  background-color: #febfc2;
  margin-bottom: 60px;
}
.home-banner .banner-image {
  width: 60%;
}
.home-banner .banner-image img {
  width: 100%;
  height: 500px;
}
.home-banner .banner-data {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
}
.home-banner .banner-data button {
  border: none;
  width: 460px;
  font-size: 23px;
  background-color: white;
  color: #f88088;
  height: 45px;
  font-weight: 600;
  border-radius: 29px;
  box-shadow: 0px 4px 4px 0px #d58e93;
}
.home-banner .banner-data h3 {
  color: white;
  font-weight: 700;
  font-size: 65px;
}

.home-banner a {
  text-decoration: none;
  color: #f88088;
}
@media (max-width: 800px) {
  .home-banner {
    max-width: 100%;
    overflow-x: hidden;
    height: 87vh;
    display: flex;
    gap: 80px;
    align-items: center;
    flex-direction: column-reverse;
    background-size: contain;
  }
  .home-banner .banner-image {
    width: 380px;
    height: 300px;
  }
  .home-banner .banner-image img {
    width: 100%;
    height: 100%;
  }
  .home-banner .banner-data {
    width: 100%;
  }
  .home-banner .banner-data button {
    height: 40px;
    width: 280px;
    font-size: 14px;
  }
  .home-banner .banner-data h3 {
    width: 100%;
    font-size: 40px;
  }
}