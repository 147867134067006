.contact-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: rgba(5, 5, 5, 0.403);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact-modal .contact-form {
  display: flex;
  padding: 40px;
}

.contact-form form {
  padding: 10px;
  padding-left: 30px;
  border-radius: 20px 0px 0px 20px;
  background-color: white;
  border: 2px solid black;
  gap: 20px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
  text-align: left;
  border-right: none;
}
.contact-form form .form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.form-group input {
  border-radius: 39px;
  padding: 10px;
}
.form-group label {
  font-weight: 600px !important;
}
.contact-form form button {
  height: 40px;
  border: none;
  background-color: #f69267;
  width: 300px;
  border-radius: 29px;
  color: white;
  font-weight: 700;
}
.contact-form .form-image {
  width: 50%;
  background: url("../assets/images/formImage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 0px 20px 20px 0px;
}
.contact-form .form-image h3 {
  text-align: left;
  color: white;
  font-weight: bold;
  max-width: 400px;
  font-size: 50px;
}
.contact-modal .contact {
  display: flex;
  flex-direction: column;
  width: 90%;

  background-color: #f69267;
}
.contact .close-btn {
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: end;
  color: white;
  align-items: end;
}
@media (max-width: 800px) {
  .contact-form .form-image {
    display: none;
  }

  .contact-form form {
    width: 100%;
    gap: 8px;
    border-radius: 15px;
    padding-left: 10px;
    border: 1px solid black;
  }
  .contact-form form button {
    width: auto;
  }
  .contact-modal .contact-form {
    display: flex;
    padding: 10px;
  }
  .contact-modal .contact {
    height: auto;
    padding: 0px;
  }
  .form-group input {
    border-radius: 39px;
    padding: 5px;
  }
  .contact-form .form-image h3 {
    text-align: left;
    color: white;
    font-weight: bold;
    max-width: 400px;
    font-size: 20px;
  }
}
