.plan-page {
  margin-top: 80px;
  margin-bottom: 80px;
}
.plans {
  background: url("../../assets/images/Plan-Banner.png");
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  border-radius: 20px;
}
.plans .plan-detials {
  margin-left: 70px;
  display: flex;
  padding-left: 20px;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  gap: 10px;
}
.plan-detials .plan-services {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
.plan-services .plan-service {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.plan-service img {
  background-color: white;
  padding: 10px;
  height: 98px;
  width: 92px;
  border-radius: 15px;
}
.add-on {
  background: url("../../assets/images/plan-banner-two.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.plan-head p {
  font-size: 20px;
  max-width: 400px;
  font-weight: 600;
}
.plan-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.plan-head h3 {
  font-size: 29px;
  font-weight: 700;
}
.plan-title h4 {
  font-size: 35px;
  font-weight: 700;
}
.plan-title p {
  font-size: 18px;
  font-weight: 500;
  max-width: 80%;
}
@media (max-width: 800px) {
  .plan-title h4 {
    max-width: 80%;
    font-weight: 700;
    font-size: 22px;
  }
  .plan-title p {
    font-size: 9px;
    font-weight: 500;
    max-width: 80%;
  }
  .plans {
    height: 140px;
    border-radius: 9px;
    padding: 10px;
  }
  .plans .plan-detials {
    margin-left: 0px;
  }
  .plan-head h3 {
    font-size: 16px;
    font-weight: 600;
  }
  .plan-head p {
    font-size: 10px;
    max-width: 200px;
    font-weight: 600;
  }

  .plan-service img {
    background-color: white;
    padding: 10px;
    height: 48px;
    width: 48px;
    border-radius: 15px;
    border-radius: 6px;
  }
  .plan-services .plan-service {
    text-align: center;
  }
  .plan-detials .plan-services {
    width: 55%;
    padding-left: 0px;
  }
  .plan-service p {
    font-size: 8px;
    font-weight: 600;
    max-width: 120px;
  }
  .plan-detials {
    gap: 3px;
  }
}
