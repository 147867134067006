.site-footer{
    margin-top: 20px;
    margin-bottom: 20px;
}
.site-footer .first-sec b a {
  color: black;
  text-decoration: none;
}
@media (max-width: 800px) {
    .site-footer{
        padding: 5px;
    }
    .site-footer .first-sec{
        font-size: 12px;
    }
    .site-footer .second-sec{
        font-size: 10px;
    }
  }