.about-section {
  padding: 80px 0px 80px 0px;
}
@media (max-width: 800px) {
  .about-image {
    width: 100%;
  }
  .about-section img {
    height: 260px;
    width: 260px;
  }
  .about-section p {
    font-size: 12px;
    margin-bottom: 20px;
    max-width: 300px;
  }
}
