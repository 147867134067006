.privacy-policy {
  padding-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.privacy-policy dl {
  width: 70%;
  font-weight: 400;
  text-align: left;
}
.privacy-policy dl dd {
  font-size: 14px;
}
.privacy-policy ul li {
  font-size: 14px;
}
.privacy-policy b {
  text-align: left;
  width: 90%;
  margin-bottom: 10px;
  color: #ff66a3;
}
.privacy-policy dl ul {
  list-style: none;
}
.privacy-policy dl ul li {
  margin-top:10px;
}

.privacy-policy dl dd p{
    font-style: italic;
    font-weight: 500;
}
