.guide-us {
  margin-top: 10px;
}
.guide-us .guide-section {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
  height: 400px;
  flex-direction: column;
  position: relative;
  padding-top: 80px;
}
.guide-us .guide-section h4 {
  font-weight: 700;
  height: 60px;
  width: 250px;
}
.guide-us .guide-section p {
  margin-top: 20px;
}

.guide-us .guide-1 {
  margin-bottom: 1px;
  z-index: 6;
  width: 200px;
  height: 200px;
  top: 70px;
  position: relative;
}
.guide-us .guide-2 {
  margin-bottom: 1px;
  width: 200px;
  height: 200px;
  z-index: 6;
  top: 70px;
  position: relative;
}
.guide-us .guide-3 {
  margin-bottom: 1px;
  width: 200px;
  height: 200px;
  z-index: 6;
  top: 70px;
  position: relative;
}
.guide-us .guide-header h3 {
   font-weight: 800;
}