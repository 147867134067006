.review-header {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: flex;
  background-color: #fff883;
  height: 230px;
  margin-top: 100px;
}
.review-header  .cat {
  height: 240px;
  width: 240px;
  position: relative;
  top: 2px;
}
.review-header  .dog {
  height: 300px;
  width: 250px;
  position: relative;
  top: -23px;
}
.review-header h2 {
  font-weight: 700;
  font-size: 65px;
  font-family: "Poppins", sans-serif;
}
.review-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
}
/* .review-image {
  width: 241px;
  height: 241px;
  border-radius: 22px;
} */
.reviews {
  position: relative;
  top: -40px;
}
.review-body {
  max-width: 235px;
  padding: 10px;
}
@media (max-width: 800px) {
  .review-header {
    padding-left: 5px;
    padding-right: 5px;
    height: 90px;
  }
  .review-header .cat {
    height: 90px;
    width: 80px;
  }
  .review-header .dog {
    height: 120px;
    width: 90px;
    top: -10px;
  }
  .review-header h2 {
    font-weight: 700;
    font-size: 18px;
  }
  .review-image {
    width: 200px;
    height: 200px;
  }
  .review-body {
    max-width: 280px;
    padding: 2px;
    font-size: 14px;
  }
  .reviews {
    position: relative;
    top: -20px;
  }
}
