.vetinary-clinics {
  width: 100%;
  height: 400px;
  background: url("../../assets/images/vetinary-clinics.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  margin: 0px !important;
}
.vetinary-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.vetinary-header h1 {
  font-size: 70px;
  font-weight: 700;
  color: white;
}
.vet-detials {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.vet-detials p {
  max-width: 900px;
}

.vet-detials .vet-logos {

  margin-top: 30px;
  display: flex;
  max-width: 93%;
  overflow-x: scroll;
  scrollbar-width: none;
  gap: 60px;
}
.vet-detials .vet-logos img {
  height: 100px;
}
.blink {
  animation: blinker 1s linear infinite;
}
.vet-detials .scroll-button{
  font-size: 46px;
  font-weight: 600
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.vet-logos {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.vet-logos img {
  margin-right: 10px;
}

.scroll-icon {
  cursor: pointer;
}

.scroll-container {
  position: relative;
  display: flex;
  align-items: center;
}

.scroll-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

/* Hide scroll buttons on mobile devices */
@media (max-width: 800px) {
  .vetinary-clinics {
    height: 100px;
  }
  .vetinary-header {
    width: 90%;
  }
  .vetinary-header h1 {
    margin-left: 60px;
    text-align: right;
    width: 100%;
    font-size: 20px;
  }
  .vetinary-header i {
    width: 80%;
    text-align: right;
    font-size: 12px;
  }
  .vet-detials .vet-logos img {
    height: 60px;
  }
  .vet-detials {
    padding: 5px;
    height: 150px;
  }
  .vet-detials p {
    font-size: 12px;
  }
  .scroll-button {
    display: none;
  }
  .scroll-container{
    height: 40px;
    margin-bottom: 20px;
  }
}
