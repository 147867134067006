.terms-conditons{
    padding-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.terms-conditons dl{
    width: 70%;
    font-weight: 500;
    text-align: left;
}
.terms-conditons dl dd{
    font-size: 14px;
}
.terms-conditons ul li{
    font-size: 14px;
}