.grooming {
  width: 100%;
  height: 400px;
  background: url("../../assets/images/grooming-servies.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  margin: 0px !important;
}
.grooming-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.grooming-header h1 {
  max-width: 400px;
  font-size: 70px;
  font-weight: 700;
  text-align: center;
  color: white;
}
@media (max-width: 800px) {
  .grooming {
    height: 100px;
  }
  .grooming-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    width: 60%;
  }
  .grooming-header h1 {
    width: 100%;
    font-size: 20px;
  }
  .grooming-header i {
    font-size: 12px;
  }
}
